/* Override Bootstrap styles */
.dropdown-menu {
  height: 51vh;
  overflow: auto;
  min-height: 400px;
}

/* Main Styles */
.logo {
  max-height: 80px;
}

.jumbotron {
  background-color: #023182;
}

.book-search-container {
  margin: 0 auto;
  padding: 2em 0.5em;
  max-width: 500px;
}

.book-search-container h1 {
  color: white;
}

.book-search-form button[type=submit] {
  margin: 1em auto;
  text-align: center;
}

.book-edition {
  font-size: 0.8em;
}

.book-category {
  font-size: 0.8em;
}

.btn-category {
  max-width: 80%;
}

.btn-category button {
  width: 100%;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.category-option {
  background-color: #636464;
}

.btn.btn-search {
  background-color: #ddd;
  color: #555;
  border-radius: 1em;
}

.btn.btn-search:hover {
  background-color: #ccc;
  color: #444;
}
